import './App.css';

import React from 'react';

import { AppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js';
import { AzureAD } from 'react-aad-msal';
import { BrowserRouter as Router } from 'react-router-dom';

import authProvider from 'mahso-common/services/authorization/authProvider';

import AuthLanding from './AuthLanding';
import { reactPlugin } from './Utilities/AppInsights';

function App() {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Router>
                <AzureAD provider={authProvider} forceLogin>
                    <AuthLanding />
                </AzureAD>
            </Router>
        </AppInsightsContext.Provider>
    );
}

export default withAITracking(reactPlugin, App, 'FacilityHierarchyReviewApp');
