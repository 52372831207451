import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { initApp } from '~/Modules/app/actions';

import IssuesTable from './Modules/Issues/IssuesTable';
import Sidebar from './Modules/NavigationContainer/Sidebar';
import UnauthorizedUser from './Modules/UnauthorizedUserPage/UnauthorizedUser';

function AuthLanding(): JSX.Element {
    const dispatch = useDispatch();
    const [authorizedUser, setAuthorizedUser] = useState(true);

    useEffect(() => {
        dispatch(initApp());
        // Change logic when api is done
        // setAuthorizedUser(inSystemUser);
    });
    return (
        <div>
            {authorizedUser && <Sidebar />}
            {authorizedUser && (
                <QueryParamProvider ReactRouterRoute={Route}>
                    <IssuesTable />
                </QueryParamProvider>
            )}
            {!authorizedUser && <UnauthorizedUser />}
        </div>
    );
}

export default AuthLanding;
